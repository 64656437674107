<template>
  <section class="space cmn_hero_banner inner_cmn_hero text-center">
    <div class="container">
      <h1>SignUp</h1>
      <div class="Breadcrums">
        <router-link :to="{ name: 'Home' }">Home</router-link>
        <img src="@/assets/images/right-arrow-black.svg" alt="" class="mx-2" />
        <router-link class="active" :to="{ name: 'SignUp' }"
          >Sign Up</router-link
        >
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>
